import { createAsyncThunk } from '@reduxjs/toolkit';

import { AnchorsErrors } from '~/api/book/chapterApiTypes';
import { anchorsSliceName } from '~/feature/jodit-plugins/anchors/anchors.slice';
import { RootState } from '~/store';

export function setAnchorErrors(anchorErrors: AnchorsErrors) {
  Object.entries(anchorErrors).forEach(([anchorId, errors]) => {
    const element = document.getElementById(anchorId);

    if (element) {
      element.dataset.errors = (errors as string[]).join(';');
    }
  });
}

export async function clearAnchorErrors(anchorIds: string[]) {
  return new Promise((resolve) => {
    for (let i = 0; i < anchorIds.length; i += 1) {
      const element = document.getElementById(anchorIds[i]);

      if (element) {
        delete element.dataset.errors;
      }
    }

    resolve(true);
  });
}

export const clearAnchorErrorsThunk = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${anchorsSliceName}/clearAnchorErrorsThunk`,
  async (
    data,
    thunkAPI,
  ) => {
    const anchorIds = thunkAPI.getState().anchors.anchors.map((anchor) => anchor.id);
    await clearAnchorErrors(anchorIds);
  },
);
