import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/store';

export const MIN_ANCHORS_COUNT = 20;

export const anchorsSelector = createSelector(
  (state: RootState) => state.anchors,
  (anchors) => anchors,
);

export const isGuardEnabledSelector = createSelector(
  anchorsSelector,
  ({ isGuardEnabled }) => isGuardEnabled,
);

export const isActiveSelector = createSelector(
  anchorsSelector,
  ({ isActive }) => isActive,
);

export const anchorsCountSelector = createSelector(
  anchorsSelector,
  ({ anchors }) => {
    return anchors?.length ?? 0;
  },
);

export const isGuardAnchorsValidSelector = createSelector(
  isGuardEnabledSelector,
  anchorsCountSelector,
  (isGuardEnabled, anchorsCount) => {
    if (!isGuardEnabled) {
      return true;
    }
    return isGuardEnabled && (anchorsCount === MIN_ANCHORS_COUNT);
  },
);
