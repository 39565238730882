import {
  AnchorsErrors,
  ChangeStatusParams,
  Chapter,
  ChapterAudio,
  ChapterTitle,
  CreateAudioChapterParams,
  CreateTextChapterParams,
  DeleteAudioChapterParams,
  DeleteTextChaptersParams,
  GetChapterListParams,
  GetTextChapterParams,
  PublistChaptersParams,
  UpdateChapterIndexParams,
  UpdateTextChapterParams,
} from '~/api/book/chapterApiTypes';
import {
  baseURL,
  provider,
} from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';
import { getCurrentTimeForApi } from '~/feature/utils/getCurrentTimeForApi';

const namespace = Symbol('namespace');

export const chapterApi = {
  [namespace]: '/v1/books/chapter',

  async createText(data: CreateTextChapterParams) {
    try {
      const response = await provider.post<
      ApiResponse<Chapter, RejectedRequest<CreateTextChapterParams & AnchorsErrors>>
      >(
        `${this[namespace]}/text`,
        data,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async createAudio(data: CreateAudioChapterParams) {
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      const headers = { 'content-type': 'multipart/form-data' };
      const response = await provider.post<
      ApiResponse<ChapterAudio, RejectedRequest<void>>
      >(
        `${this[namespace]}/text/${data.id}/audio`,
        formData,
        { headers },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async deleteAudio(data: DeleteAudioChapterParams) {
    try {
      await provider.delete<
      ApiResponse<Chapter, RejectedRequest<void>>
      >(
        `${this[namespace]}/text/${data.id}/audio`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async updateText(data: UpdateTextChapterParams) {
    try {
      const { chapterId, ...otherData } = data;

      const response = await provider.put<
      ApiResponse<
      void,
      RejectedRequest<Omit<UpdateTextChapterParams, 'chapterId'> & AnchorsErrors>
      >
      >(`${this[namespace]}/text/${chapterId}`, otherData);
      if ('data' in response) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  uploadImageUrl() {
    return `${baseURL}${this[namespace]}/image`;
  },
  async getText(data: GetTextChapterParams) {
    const params = getCurrentTimeForApi();

    try {
      const response = await provider.get<
      ApiResponse<Chapter, RejectedRequest<void>>
      >(`${this[namespace]}/text/${data.id}`, { params });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getChapterList(data: GetChapterListParams) {
    try {
      const response = await provider.get<
      ApiResponse<ChapterTitle[], RejectedRequest<void>>
      >(`${this[namespace]}s/text`, { params: { bookId: data.id } });

      if ('data' in response) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async deleteText(data: DeleteTextChaptersParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/text/${data.id}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async updateIndex(data: UpdateChapterIndexParams) {
    try {
      const response = await provider.put<
      ApiResponse<ChapterTitle[], RejectedRequest<Omit<UpdateChapterIndexParams, 'id'>>>
      >(
        `${this[namespace]}/text/${data.id}/number`,
        { number: data.number },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeStatus(data: ChangeStatusParams) {
    try {
      const response = await provider.put<
      ApiResponse<ChapterTitle, RejectedRequest<void>>
      >(
        `${this[namespace]}/text/${data.id}/status`,
        data,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async publistChapters(data: PublistChaptersParams) {
    try {
      await provider.post<
      ApiResponse<ChapterTitle, RejectedRequest<void>>
      >(
        `${this[namespace]}/text/massPublish`,
        data,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

};
