import { createAsyncThunk } from '@reduxjs/toolkit';

import { bookApi } from '~/api/book/bookApi';
import { BookIdType, PublishStatusEnum } from '~/api/book/bookApiTypes';
import { chapterApi } from '~/api/book/chapterApi';
import {
  AnchorsErrors,
  ChangeStatusParams, Chapter, ChapterTitle,
  CreateAudioChapterParams, CreateTextChapterParams, CreateTextChapterRejectValue,
  DeleteAudioChapterParams,
  GetChapterListParams,
  GetPublishedChapterListByBookSlugParams, GetTextChapterParams,
  UpdateChapterIndexParams,
} from '~/api/book/chapterApiTypes';
import { HttpValidationError } from '~/api/provider/providerErrors';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import { getBook } from '~/feature/book/book.data';
import { chapterActions } from '~/feature/chapter/chapter.slice';
import { setAnchorErrors } from '~/feature/jodit-plugins/anchors/anchors.data';
import { isGuardEnabledSelector } from '~/feature/jodit-plugins/anchors/anchors.selector';
import { anchorsActions } from '~/feature/jodit-plugins/anchors/anchors.slice';
import { refreshBookCash } from '~/feature/refreshCash/refreshBookCash';
import { GraphqlChapters } from '~/graphql/books/factory/book/ChaptersFactoryTypes';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

interface ChangeChapterIndexParams extends UpdateChapterIndexParams {
  bookId: BookIdType;
}

export const changeChapterIndex = createAsyncThunk<any, ChangeChapterIndexParams, {
  rejectValue: { error: string }, state: RootState,
}>(
  'chapter/changeChapterIndex',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { currentBook } = thunkAPI.getState().book;
      const result = await chapterApi.updateIndex({ id: data.id, number: data.number });
      refreshBookCash(currentBook.slug);
      if (result && 'data' in result) {
        thunkAPI.dispatch(chapterActions.setChapters(result.data));
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

interface SaveChapterProps extends CreateTextChapterParams {
  chapterId: string
}

export const saveChapter = createAsyncThunk<void, SaveChapterProps, {
  rejectValue: { message?: string; errors?: CreateTextChapterRejectValue }; state: RootState;
}>(
  'chapter/saveChapter',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { currentBook } = thunkAPI.getState().book;
      const { isEnableSchedulePublicationChapter } = thunkAPI.getState().bookEdit.editChapter;

      if (isEnableSchedulePublicationChapter && !data.publicationScheduledFor) {
        const { message } = await import('~/atomic/atom/message');
        message.error('Необходимо выбрать дату запланированной публикации');
        return thunkAPI.rejectWithValue({ message: 'Необходимо выбрать дату запланированной публикации' });
      }

      if (data.chapterId === 'new') {
        const { chapterId, ...createChapterData } = data;
        await chapterApi.createText(createChapterData);
      } else {
        const { bookId, ...updateChapterData } = data;

        const isGuardEnabled = isGuardEnabledSelector(thunkAPI.getState());
        await chapterApi.updateText({ ...updateChapterData, protected: isGuardEnabled });
      }
      thunkAPI.dispatch(chapterActions.changeCurrentEditChapter({
        content: data.content,
        name: data.name,
      }));
      refreshBookCash(currentBook.slug);
    } catch (error) {
      if (error instanceof HttpValidationError) {
        const errors = error.getErrors();
        if (errors && 'anchors' in errors) {
          if ('general' in errors.anchors) {
            const { message } = await import('~/atomic/atom/message');
            message.error(
              ((errors.anchors as unknown) as AnchorsErrors).general ?? 'Ошибка валидации',
            );
          }
          setAnchorErrors((errors.anchors as unknown) as AnchorsErrors);
        } else {
          const { message } = await import('~/atomic/atom/message');
          message.error(error?.message ?? 'Ошибка валидации');
        }
        return thunkAPI.rejectWithValue({ message: error.message, errors: error.getErrors() });
      }
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);

export const createAudioChapter = createAsyncThunk<void, CreateAudioChapterParams, {
  rejectValue: { error: string }; state: RootState;
}>(
  'chapter/createAudioChapter',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(chapterActions.setIsLoadAudioChapterLoading(true));
      const { currentBook } = thunkAPI.getState().book;
      const result = await chapterApi.createAudio(data);

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        const { bookChapters } = thunkAPI.getState().chapter;
        thunkAPI.dispatch(chapterActions.setChapters(
          bookChapters.map((chapter) => {
            if (Number(chapter.id) === Number(data.id)) {
              return {
                ...chapter,
                audio: result.data,
              };
            }

            return chapter;
          }),
        ));
      }
      refreshBookCash(currentBook.slug);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(chapterActions.setIsLoadAudioChapterLoading(false));
    }
  },
);

export const deleteAudioChapter = createAsyncThunk<void, DeleteAudioChapterParams, {
  rejectValue: { error: string }; state: RootState;
}>(
  'chapter/deleteAudioChapter',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { currentBook } = thunkAPI.getState().book;
      thunkAPI.dispatch(chapterActions.setIsDeleteAudioChapterLoading(true));
      await chapterApi.deleteAudio(data);
      const { message } = await import('~/atomic/atom/message');
      message.success('Аудио глава удалена');
      thunkAPI.dispatch(getChaptersList({ id: currentBook.id }));
      refreshBookCash(currentBook.slug);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(chapterActions.setIsDeleteAudioChapterLoading(false));
    }
  },
);

export const deleteChapter = createAsyncThunk<void, any, {
  rejectValue: { error: string }, state: RootState
}>(
  'chapter/deleteChapter',
  async (
    chapterId: string,
    thunkAPI,
  ) => {
    try {
      const { currentBook } = thunkAPI.getState().book;
      const result = await chapterApi.deleteText({ id: chapterId });

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
      refreshBookCash(currentBook.slug);
      thunkAPI.dispatch(chapterActions.deleteChapterFromBookChapters(chapterId));
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getChaptersList = createAsyncThunk<ChapterTitle[], GetChapterListParams, {
  rejectValue: { error: string };
}>(
  'chapter/getChaptersList',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await chapterApi.getChapterList(data);

      if (result && 'data' in result) {
        const chapters = result.data.sort((prevChapter, nextChapter) => {
          return prevChapter.number - nextChapter.number;
        });

        thunkAPI.dispatch(chapterActions.setChapters(chapters));

        return chapters;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getPublishedChapterListByBookSlugFromGraphql = createAsyncThunk<
GraphqlChapters,
GetPublishedChapterListByBookSlugParams, {
  rejectValue: { error: string }, state: RootState
}>(
  'chapter/getPublishedChapterListByBookSlugFromGraphql',
  async (
    data,
    thunkAPI,
  ) => {
    const { isGetPublishChaptersLoading } = thunkAPI.getState().chapter;
    try {
      if (!isGetPublishChaptersLoading) {
        thunkAPI.dispatch(chapterActions.changeIsGetPublishChaptersLoading(true));
        const { booksGraphql } = await import('~/graphql/books/booksGraphql');
        const result = await booksGraphql.getChapters({
          ...data,
          token: getAuthTokenFromClient(),
        });

        thunkAPI.dispatch(chapterActions.changeGraphqlChapters(result));
        return result;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(chapterActions.changeIsGetPublishChaptersLoading(false));
    }
  },
);

export const getTextChapter = createAsyncThunk<Chapter, GetTextChapterParams, {
  rejectValue: { error: string }, state: RootState
}>(
  'chapter/getTextChapter',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(chapterActions.changeIsCurrentChapterLoading(true));
      const result = await chapterApi.getText(data);
      if (result && 'data' in result) {
        thunkAPI.dispatch(anchorsActions.setGuardStatus(result.data.protected));
        thunkAPI.dispatch(chapterActions.changeCurrentEditChapter(result.data));
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(chapterActions.changeIsCurrentChapterLoading(false));
    }
  },
);

export const changeChapterStatus = createAsyncThunk<ChapterTitle, ChangeStatusParams, {
  rejectValue: { error: string }, state: RootState
}>(
  'chapter/changeChapterStatus',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { currentBook } = thunkAPI.getState().book;
      const result = await chapterApi.changeStatus(data);

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        thunkAPI.dispatch(chapterActions.changeChapter(result.data));
        return result.data;
      }
      refreshBookCash(currentBook.slug);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const publishChapters = createAsyncThunk<void, void, {
  rejectValue: { error: string }, state: RootState;
}>(
  'chapter/publishChapters',
  async (
    _,
    thunkAPI,
  ) => {
    const { isGetPublishChaptersLoading, bookChapters } = thunkAPI.getState().chapter;
    const { currentBook: { id: bookId, slug } } = thunkAPI.getState().book;
    if (!isGetPublishChaptersLoading) {
      thunkAPI.dispatch(chapterActions.setPublishChaptersLoading(true));
      try {
        await chapterApi.publistChapters({
          chapterIds: bookChapters
            .map((chapter) => Number(chapter.id)),
        });

        const [bookChaptersData] = await Promise.all([
          chapterApi.getChapterList({ id: bookId }),
          bookApi.changePublishStatus({
            id: bookId,
            statusPublish: PublishStatusEnum.Published,
          }),
        ]);
        refreshBookCash(slug);

        if ('data' in bookChaptersData) {
          thunkAPI.dispatch(chapterActions.setChapters(
            bookChaptersData.data.sort((prevChapter, nextChapter) => {
              return prevChapter.number - nextChapter.number;
            }),
          ));
        }
        await thunkAPI.dispatch(getBook(bookId));
      } catch (error) {
        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
        }
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(chapterActions.setPublishChaptersLoading(false));
      }
    }
  },
);
